// @flow
import validator from 'validator';
import moment from 'moment-timezone';

type FieldValue = any;

export const isEmpty = (value: FieldValue) => {
  try {
    return value === undefined || value === null || value.trim() === '';
  } catch (e) {
    return false;
  }
};

export function isRequired(value: FieldValue) {
  const invalid = isEmpty(value);
  if (invalid) {
    return 'Campo obligatorio';
  }
}

export function isEmail(value: FieldValue) {
  if (isEmpty(value)) {
    return isRequired(value);
  }

  if (!validator.isEmail(value)) {
    return 'Debe ingresar una dirección válida';
  }
}

export function isValidPassword(value: FieldValue) {
  if (isEmpty(value)) {
    return isRequired(value);
  }

  if (value.length < 8) {
    return 'Tu contraseña debe tener al menos 8 caracteres';
  }
}

export function isRequiredArray(value: FieldValue) {
  if (!Array.isArray(value) || (value && value.length <= 0)) {
    return 'Campo obligatorio';
  }
}

export function isValidPercent(value: FieldValue) {
  const number = parseInt(value, 10);

  if (isNaN(number) || number < 0 || number > 100) {
    return 'Ingresa un número entre 0 y 100';
  }
}

export function isValidServiceStartingDatetime(value: FieldValue) {
  const datetime = moment(value);

  if (datetime.minutes() !== 0 && datetime.minutes() !== 30) {
    return 'La hora debe ser 00 o 30 min';
  }
}

export function isValidTime(value: FieldValue = '') {
  const matches = value.match(/(\d{2}):(\d{2})(.*)/);
  const hour = matches && parseInt(matches[1], 10);
  const minutes = matches && parseInt(matches[2], 10);
  const extra = matches && matches[3];

  if (
    extra ||
    typeof hour !== 'number' ||
    typeof minutes !== 'number' ||
    isNaN(hour) ||
    isNaN(minutes) ||
    hour < 0 ||
    hour > 23 ||
    minutes < 0 ||
    minutes > 59
  ) {
    return 'Ingresa una hora válida (00:00 24hrs)';
  }
}
