// @flow
import React from 'react';

import redux from './redux';
import Modal from './Modal';

import AssignServiceAvailableOperatorsModal from '@src/components/modals/AssignServiceAvailableOperatorsModal';
import AssignRecurrenceAvailableOperatorsModal from '@src/components/modals/AssignRecurrenceAvailableOperatorsModal';
import AssignTaskOperatorsModal from '@src/components/modals/AssignTaskOperatorsModal';
import CreateExtraServiceModal from '@src/components/modals/CreateExtraServiceModal';
import UpdateServiceModal from '@src/components/modals/UpdateServiceModal';
import CancelServiceModal from '@src/components/modals/CancelServiceModal';
import CreateServiceCheckInModal from '@src/components/modals/CreateServiceCheckInModal';
import CreateServiceNoShowModal from '@src/components/modals/CreateServiceNoShowModal';
import UpdateServiceNoShowModal from '@src/components/modals/UpdateServiceNoShowModal';
import CreateServiceNoShowCorrectionModal from '@src/components/modals/CreateServiceNoShowCorrectionModal';
import ConfirmationModal from '@src/components/modals/ConfirmationModal';
import DeactivateOperatorModal from '@src/components/modals/DeactivateOperatorModal';
import MonthlyCleaningInvoicingModal from '@src/components/modals/MonthlyCleaningInvoicingModal';
import CreateInvoicePaymentModal from '@src/components/modals/CreateInvoicePaymentModal';

function getModalElement(
  type?: string,
  wrapperProps: *,
  modalProps: Object = {}
) {
  switch (type) {
    case 'AssignRecurrenceAvailableOperatorsModal':
      return (
        <Modal {...wrapperProps}>
          <AssignRecurrenceAvailableOperatorsModal {...modalProps} />
        </Modal>
      );
    case 'AssignServiceAvailableOperatorsModal':
      return (
        <Modal {...wrapperProps}>
          <AssignServiceAvailableOperatorsModal {...modalProps} />
        </Modal>
      );
    case 'AssignTaskOperatorsModal':
      return (
        <Modal {...wrapperProps}>
          <AssignTaskOperatorsModal {...modalProps} />
        </Modal>
      );
    case 'CreateExtraServiceModal':
      return (
        <Modal {...wrapperProps}>
          <CreateExtraServiceModal {...modalProps} />
        </Modal>
      );
    case 'UpdateServiceModal':
      return (
        <Modal {...wrapperProps}>
          <UpdateServiceModal {...modalProps} />
        </Modal>
      );
    case 'CancelServiceModal':
      return (
        <Modal {...wrapperProps}>
          <CancelServiceModal {...modalProps} />
        </Modal>
      );
    case 'CreateServiceCheckInModal':
      return (
        <Modal {...wrapperProps}>
          <CreateServiceCheckInModal {...modalProps} />
        </Modal>
      );
    case 'CreateServiceNoShowModal':
      return (
        <Modal {...wrapperProps}>
          <CreateServiceNoShowModal {...modalProps} />
        </Modal>
      );
    case 'UpdateServiceNoShowModal':
      return (
        <Modal {...wrapperProps}>
          <UpdateServiceNoShowModal {...modalProps} />
        </Modal>
      );
    case 'CreateServiceNoShowCorrectionModal':
      return (
        <Modal {...wrapperProps}>
          <CreateServiceNoShowCorrectionModal {...modalProps} />
        </Modal>
      );
    case 'ConfirmationModal':
      return (
        <Modal {...wrapperProps}>
          <ConfirmationModal {...modalProps} />
        </Modal>
      );
    case 'DeactivateOperatorModal':
      return (
        <Modal {...wrapperProps}>
          <DeactivateOperatorModal {...modalProps} />
        </Modal>
      );
    case 'MonthlyCleaningInvoicingModal':
      return (
        <Modal {...wrapperProps}>
          <MonthlyCleaningInvoicingModal {...modalProps} />
        </Modal>
      );
    case 'CreateInvoicePaymentModal':
      return (
        <Modal {...wrapperProps}>
          <CreateInvoicePaymentModal {...modalProps} />
        </Modal>
      );

    default:
      if (type) {
        console.log(
          'Modal ' +
            type +
            'not found on src/components/Modal/ModalContainer.js '
        );
      }
      return null;
  }
}

type ModalContainerProps = {
  data: ?{
    type: string,
    closeable?: boolean,
  },
  close: () => void,
};

export function ModalContainer(props: ModalContainerProps) {
  const { data = {}, close } = props;
  const { type, closeable, ...rest } = data || {};

  const wrapperProps = {
    closeable,
    closeModal: close,
  };

  const modalProps = {
    closeModal: close,
    ...rest,
  };

  return getModalElement(type, wrapperProps, modalProps);
}

export default redux(ModalContainer);
